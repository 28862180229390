:root {
  --status-color-0: #000000D9;
  --status-color-1: #1890FFD9;
  --status-color-2: #13C2C2D9;
  --status-color-3: #FAAD14D9;
  --status-color-4: #722ED1D9;
  --status-color-5: #52C41AD9;
  --status-color-6: #FA541CD9;
  --status-color-7: #FAAD14D9;
  --status-color-8: #E50100D9;

  --status-border-0: #D9D9D9;
  --status-border-1: #91D5FF;
  --status-border-2: #87E8DE;
  --status-border-3: #FFE58F;
  --status-border-4: #D3ADF7;
  --status-border-5: #B7EB8F;
  --status-border-6: #FFA39E;
  --status-border-7: #FFD591;
  --status-border-8: #fb6f6f;

  --status-bg-0: #FAFAFA;
  --status-bg-1: #E6F7FF;
  --status-bg-2: #E6FFFB;
  --status-bg-3: #FFFBE6;
  --status-bg-4: #F9F0FF;
  --status-bg-5: #F6FFED;
  --status-bg-6: #FFF1F0;
  --status-bg-7: #FFF7E6;
  --status-bg-8: #fcdddd;
}


$status-color-0: #000000D9;
$status-color-1: #1890FFD9;
$status-color-2: #13C2C2D9;
$status-color-3: #FAAD14D9;
$status-color-4: #722ED1D9;
$status-color-5: #52C41AD9;
$status-color-6: #FA541CD9;
$status-color-7: #FAAD14D9;
$status-color-8: #E50100D9;

$status-border-0: #D9D9D9;
$status-border-1: #91D5FF;
$status-border-2: #87E8DE;
$status-border-3: #FFE58F;
$status-border-4: #D3ADF7;
$status-border-5: #B7EB8F;
$status-border-6: #FFA39E;
$status-border-7: #FFD591;
$status-border-8: #fb6f6f;

$status-bg-0: #FAFAFA;
$status-bg-1: #E6F7FF;
$status-bg-2: #E6FFFB;
$status-bg-3: #FFFBE6;
$status-bg-4: #F9F0FF;
$status-bg-5: #F6FFED;
$status-bg-6: #FFF1F0;
$status-bg-7: #FFF7E6;
$status-bg-8: #fcdddd;

.status {
  border-radius: 12px;
  padding: 4px 12px;
  font-size: 12px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.status-0 {
  color: $status-color-0;
  border: 1px solid $status-border-0;
  background: $status-bg-0;
}

.status-1 {
  color: $status-color-1;
  border: 1px solid $status-border-1;
  background: $status-bg-1;
}


.status-2 {
  color: $status-color-2;
  border: 1px solid $status-border-2;
  background: $status-bg-2;
}


.status-3 {
  color: $status-color-3;
  border: 1px solid $status-border-3;
  background: $status-bg-3;
}


.status-4 {
  color: $status-color-4;
  border: 1px solid $status-border-4;
  background: $status-bg-4;
}


.status-5 {
  color: $status-color-5;
  border: 1px solid $status-border-5;
  background: $status-bg-5;
}


.status-6 {
  color: $status-color-6;
  border: 1px solid $status-border-6;
  background: $status-bg-6;
}


.status-7 {
  color: $status-color-7;
  border: 1px solid $status-border-7;
  background: $status-bg-7;
}

.status-8 {
  color: $status-color-8;
  border: 1px solid $status-border-8;
  background: $status-bg-8;
}
