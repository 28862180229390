.ant-table-wrapper {
  .ant-table-container {
    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        text-align: center;
      }
    }
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px;
  }
}


.header-brand img {
  display: none;
}

.color-primary {
  color: #007ad2 !important;
}

@media (max-width: 992px) {
  .header-brand img {
    display: block;
    width: 85px;
  }

  .d-mb-none {
    display: none;
  }
}
